import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentsModule } from '../../components/components.module';
import { Store } from '@ngrx/store';
import { colors } from 'src/app/auth/store';
import { ButtonDesign } from '../../components/button/button.component';
import { InputComponent } from "../../components/input/input.component";
import { TextAreaComponent } from 'src/app/library/components/text-area/text-area.component';
import { LabelComponent } from 'src/app/library/components/label/label.component';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [CommonModule, ComponentsModule, InputComponent, TextAreaComponent, LabelComponent],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  colors$ = this.store.select(colors);
  closureComments: string = '';
  errorMessage: string = '';
  maxLen = 5000;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ErrorData,
    private store: Store,
    private dialogRef: MatDialogRef<ErrorComponent> 
  ) { }

  ngOnInit(): void { 
  }

  closeDialog(result: string): void {
    this.dialogRef.close({ result, closureComments: this.closureComments });
  }

  isTextAreaEmpty(): boolean {
    return this.closureComments.trim() === '';
  }

  onCommentChange(): void {
    if (this.closureComments.length > this.maxLen) {
      this.errorMessage = `Only ${this.maxLen} characters are allowed.`;
    } else {
      this.errorMessage = '';
    }
  }

  shouldDisableButton(): boolean {
    return this.data.isCommentBox && (this.isTextAreaEmpty() || this.closureComments.length > this.maxLen);
  }

  ngonDestroy(): void {
    this.closureComments = '';
  }
}

export interface ErrorData {
  message: string;
  button1Name?: string;
  button2Name?: string;
  button2Type?: ButtonDesign;
  button3Name?: string;
  dismissData?: any;
  isCommentBox?: boolean;
  closureComments?: string;
}