<textarea
  [ngClass]="size"
  appAutoExpand
  (newHeight)="height = $event"
  [style.borderColor]="color$ | async"
  [style.height]="height"
  [size]="placehoderSize"
  [color]="(colors$ | async).secondary3"
  appScrollIntoView
  [isScroll]="isError"
  appInputStyle
  [placeholder]="placeholder"
  [value]="value"
  [name]="id"
  [id]="id"
  [rows]="rows"
  [disabled]="isDisabled"
  (input)="onChange($event)"
  (blur)="onBlur($event)"
  (focus)="isFocus = true"
  (focusout)="isFocus = false"
  autocomplete="off"
></textarea>
<div class="flex justify-between">
  <div>
    <app-form-error
      *ngIf="isRequired"
      [isPadding]="false"
      [message]="strings.required"
    ></app-form-error>
    <app-form-error
      *ngIf="isMaxLength"
      [isPadding]="false"
      [message]="maxLengthErrorMessage"
    ></app-form-error>
    <app-form-error
      *ngIf="isMinLength"
      [isPadding]="false"
      [message]="minLengthErrorMessage"
    ></app-form-error>
  </div>
  <p class="font-14-i" [style.color]="color$ | async" *ngIf="maxLength">
    {{ value.length }} / {{ maxLength }}
  </p>
  <p class="font-14-i" [style.color]="color$ | async" *ngIf="minLength">
    {{ value.length }} / {{ minLength }}
  </p>
</div>
