import {
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appAutoExpand]',
  standalone: true,
})
export class AutoExpandDirective implements OnInit {
  @Output() newHeight = new EventEmitter<string>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    setTimeout(() => this.emitHeight(), 100);
    this.el.nativeElement.oninput = () => this.emitHeight();
  }

  emitHeight() {
    const {clientHeight, scrollHeight} = this.el.nativeElement;
    if (clientHeight === scrollHeight) return;
    this.newHeight.emit(scrollHeight + 16 + 'px');
  }
}
