import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {colorsCSS} from 'src/app/auth/store';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-form-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {
  colorCSS$ = this.store.select(colorsCSS);
  @Input() message: string;
  @Input() control: AbstractControl;
  @Input() errorKey: string;
  @Input() isPadding = true;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  get isMessage() {
    if (this.control) {
      return this.control.touched && this.control.hasError(this.errorKey);
    }
    return true;
  }
}
