import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appScrollIntoView]',
})
export class ScrollIntoViewDirective implements OnChanges {
  @Input() isScroll = false;
  constructor(private element: ElementRef) {}

  ngOnChanges(): void {
    if (!this.isScroll) return;
    const {nativeElement} = this.element;
    nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
  }
}
