import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {colorsCSS} from 'src/app/auth/store';

@Component({
  selector: 'app-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
  @Input() label: string;
  @Input() font: 'fontS1' | 'fontS2' = 'fontS1';
  @Input() size: 'font-16' | 'font-14' = 'font-16';
  @Input() isRequired = false;
  @Input() isSpacing = true;

  colorCSS$ = this.store.select(colorsCSS);

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
