import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {
  ApplicationInsights,
  Exception,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import {filter} from 'rxjs';
import {environment} from 'src/environments/environment.uat';

@Injectable()
export class MyMonitoringService {
  appInsights: ApplicationInsights;
  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        autoTrackPageVisitTime: true,
        enableRequestHeaderTracking: true,
        enablePerfMgr: true,
        enableAjaxPerfTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    this.loadCustomTelimetryProperties();
    this.createRouterSubscription();
    //this.setUserId('123507');
    var telemetryInitializer = envelope => {
      envelope.data = envelope.data || {};
      envelope.data.userID = localStorage.getItem('User-Id');
      return envelope.data.userID;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  private loadCustomTelimetryProperties() {
    this.appInsights.addTelemetryInitializer(envelope => {
      var item = envelope.baseData;
      item['properites'] = item['properites'] || {};
      item['properites']['applicationName'] = 'CodeX';
      item['properites']['EMPID'] = localStorage.getItem('User-Id');
    });
  }

  // setUserId(userId: string) {
  //   this.appInsights.setAuthenticatedUserContext(userId);
  // }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: {[key: string]: any}) {
    this.appInsights.trackEvent({name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: {[key: string]: any}) {
    this.appInsights.trackMetric({name: name, average: average}, properties);
  }

  logErrorException(error: Error) {
    let exception: IExceptionTelemetry = {
      exception: error,
    };
    this.appInsights.trackException(exception);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: {[key: string]: any}) {
    this.appInsights.trackTrace({message: message}, properties);
  }

  private createRouterSubscription() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.logPageView(null, event.urlAfterRedirects);
      });
  }
  
}
