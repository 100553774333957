import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appInputStyle]',
  standalone: true,
})
export class InputStyleDirective {
  @Input() color: string;
  @Input() size: string;
  @Input() hoverColor: string;
  private input: HTMLInputElement;

  constructor(private el: ElementRef) {
    this.input = this.el.nativeElement;
  }

  ngOnInit() {
    this.input.style.setProperty('--placeHolder-color', this.color);
    this.input.style.setProperty('--placeHolder-font-size', this.size);
  }
}
