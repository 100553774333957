<div class="p-24 dialog-container relative">
  <div *ngIf="data.button1Name" class="icon-position">
    <app-icons
      [isPointer]="true"
      [mat-dialog-close]="data.dismissData || ''"
      name="remove"
      [size]="16"
      [color]="(colors$ | async).secondary3"
    ></app-icons>
  </div>
  <app-icons
    name="alert"
    [size]="80"
    [opacity]="0.33"
    [color]="(colors$ | async).primary"
  ></app-icons>
  <p class="p-24 font-20-b text-center">{{ data.message }}</p>
  <div *ngIf="data.isCommentBox === true" class="pb-16 comment-box-container scrollable">
    <div class="comment-box-inner">
      <app-label label="Closure comments" [isRequired]="true" class="comment-label"></app-label>
      <app-text-area
        placeholder="'Enter Comment to Close the action'"
        class="comment-input"
        [(ngModel)]="closureComments"
        (ngModelChange)="onCommentChange()"
        [maxLength]="maxLen"
        [ngClass]="{'error': isTextAreaEmpty()}"
        [required]="true"
      ></app-text-area>
      <p class="error-message" *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
  <div class="flex w-100 justify-around">
    <app-button
      *ngIf="data.button2Name"
      [name]="data.button2Name"
      [design]="data.button2Type"
      [mat-dialog-close]="data.button2Name"
    ></app-button>
    <app-button *ngIf="data.button1Name"
    [name]="data.button1Name"
    [mat-dialog-close]="data.button1Name"
    [disabled]="data.isCommentBox && shouldDisableButton()"
    (click)="data.isCommentBox ? closeDialog(data.button1Name) : null"
  ></app-button>
  </div>
  <div class="pt-8" *ngIf="data.button3Name">
    <app-link
      [title]="data.button3Name"
      [mat-dialog-close]="data.button3Name"
    ></app-link>
  </div>
</div>