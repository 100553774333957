import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {colors} from 'src/app/auth/store';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {InputStyleDirective} from '../../directives/input-style.directive';
import {FormErrorComponent} from '../form-error/form-error.component';
import {appStrings} from '../../constants/strings';
import {ScrollIntoViewDirective} from '../../directives/scroll-into-view.directive';
import {replaceText} from 'src/Utils';
import {map} from 'rxjs';
import {AutoExpandDirective} from './auto-expand.directive';

@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [
    CommonModule,
    InputStyleDirective,
    FormErrorComponent,
    ScrollIntoViewDirective,
    AutoExpandDirective,
  ],
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements OnInit {
  colors$ = this.store.select(colors);

  isFocus = false;
  control: AbstractControl;
  strings = appStrings.form;

  @Input() height: string = '104px';
  @Input() placeholder = 'Placeholder';
  @Input() _value = '';
  @Input() id = '';
  @Input() rows = '4';
  @Input() maxLength = 0;
  @Input() minLength = 0;
  @Input() size: 'font-16' | 'font-14' = 'font-16';
  @Input() isDisabled: boolean = false;

  @Output() textChange = new EventEmitter<string>();

  constructor(private store: Store) {}

  ngOnInit(): void {}

  writeValue(value: any) {
    this.value = value;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  get color$() {
    return this.colors$.pipe(
      map(colors => {
        return this.isError
          ? colors.red
          : this.isFocus
          ? colors.primary
          : colors.secondary3;
      }),
    );
  }

  get placehoderSize() {
    return `${+this.size.split('-')[1] - 2}px`;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(this.value);
  }

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  onBlur($event) {
    this.propagateTouch($event);
  }

  onChange(event) {
    this.value = event.target.value;
    this.textChange.emit(event.target.value);
  }

  validate(control: AbstractControl) {
    this.control = control;
  }

  get isError() {
    return this.control ? this.control.touched && this.control.invalid : false;
  }

  get isRequired() {
    return this.control
      ? this.control.touched && this.control.hasError('required')
      : false;
  }

  get isMaxLength() {
    return this.control
      ? this.control.touched && this.control.hasError('maxlength')
      : false;
  }

  get isMinLength() {
    return this.control
      ? this.control.touched && this.control.hasError('minlength')
      : false;
  }

  get maxLengthErrorMessage() {
    return replaceText(
      this.strings.maxLength,
      this.control.errors['maxlength'].requiredLength,
    );
  }

  get minLengthErrorMessage() {
    return replaceText(
      this.strings.minLength,
      this.control.errors['minlength'].requiredLength,
    );
  }
}
